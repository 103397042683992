// game lobby actions
export const CREATE_GAME = "CREATE_GAME";
export const JOIN_GAME = "JOIN_GAME";
export const LEAVE_GAME = "LEAVE_GAME";
export const UPDATE_GAME_LIST = "UPDATE_GAME_LIST";
export const UPDATE_PLAYER_LIST = "UPDATE_PLAYER_LIST";
export const UPDATE_PLAYER_NAME = "UPDATE_PLAYER_NAME";

export const ADD_MOVE = "ADD_MOVE";
export const SEND_MOVE = "SEND_MOVE";
export const SHUFFLE_TILES = "SHUFFLE_TILES";
export const UPDATE_PLAYER_TURN = "UPDATE_PLAYER_TURN";
export const SET_PLAYER_READY = "SET_PLAYER_READY";
export const UPDATE_PLAYER_READY = "UPDATE_PLAYER_READY";
export const UPDATE_GAME_PLAYER_LIST = "UPDATE_GAME_PLAYER_LIST";
export const UPDATE_GAME_STATUS = "UPDATE_GAME_STATUS";
export const TOGGLE_GAME_OVER_MODAL = "TOGGLE_GAME_OVER_MODAL";
export const TOGGLE_GAME_RULES_MODAL = "TOGGLE_GAME_RULES_MODAL";
export const NEW_GAME = "NEW_GAME";
export const RESET_GAME = "RESET_GAME";
export const UPDATE_GAME_STATS = "UPDATE_GAME_STATS";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";
